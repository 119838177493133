import React, { useState, useMemo, useEffect } from "react";
import styles from "./Form.module.scss";
import Select, { components } from "react-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Countries from "../../countries.json";
import axios from "axios";

export default function Form() {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phone, setPhone] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [submit, setSubmit] = useState(false);
  const [countries, SetCountries] = useState([]);
  const [country, setCountry] = useState([]);

  const handleSetSubmit = () => {
    setSubmit(true);
  };

  const [formData, setFormData] = useState({
    // country: null,
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
  });

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhone = (phone) =>
    /^\+?[0-9]{1,3}[-.\s]?[(]?[0-9]{1,4}[)]?[-.\s]?[0-9]{3,4}[-.\s]?[0-9]{3,4}$/.test(
      phone
    );

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "country":
        if (!value) error = t("Country is required");
        break;
      case "phone":
        if (!validatePhone(value)) error = t("Invalid phone number");
        break;
      case "email":
        if (!validateEmail(value)) error = t("Invalid email address");
        break;
      case "firstName":
        if (!value) error = t("First Name is required");
        break;
      case "lastName":
        if (!value) error = t("Last Name is required");
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }));
  };

  const handleCountryChange = (value) => {
    setCountry(value);
    validateField("country", value);
  };

  const handleChange = (e, fieldName) => {
    const value = e.target.value;
    switch (fieldName) {
      case "phone":
        setPhone(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      default:
        break;
    }
    validateField(fieldName, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = Object.keys(errors).filter((key) => errors[key]);
    if (formErrors.length === 0) {
      try {
        const { data } = await axios.post(
          "https://ru.regtruster.pro:8081/registration",
          {
            // country: country.value,
            phone,
            email,
            name: firstName,
            surname: lastName,
          }
        );
        if (data.length > 0) {
          setSubmit(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  // // Используйте useEffect для отслеживания изменения formData
  // useEffect(() => {
  //     console.log("Updated formData:", formData);
  // }, []);

  const Control = ({ children, ...props }) => {
    // console.log(children[0].props.selectProps.value);
    return (
      <components.Control {...props}>
        <div className={styles.country_start}>
          <span
            className={
              children[0].props.selectProps.value.length === 0
                ? styles.icon
                : styles.flag
            }
          >
            {children[0].props.selectProps.value.length === 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"1.7vh"}
                height={"1.6vh"}
                viewBox="0 0 17 16"
                fill="none"
              >
                <g clipPath="url(#clip0_420_48527)">
                  <ellipse
                    cx="8.84733"
                    cy="6.66667"
                    rx="4.66667"
                    ry="4.66667"
                    stroke="#51525C"
                  />
                  <path
                    d="M3.51465 11.0415C4.77357 12.4481 6.60307 13.3333 8.63931 13.3333C12.4365 13.3333 15.5146 10.2551 15.5146 6.45798C15.5146 4.42173 14.6294 2.59223 13.2229 1.33331"
                    stroke="#51525C"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.51465 3.0351C5.90477 3.40352 6.70842 4.41053 6.80205 5.49122C6.88957 6.50137 7.5325 7.32219 8.51465 7.33331C8.89209 7.33758 9.27382 7.05481 9.27285 6.66337C9.27255 6.54233 9.25328 6.41857 9.22313 6.30471C9.1812 6.14634 9.17748 5.96415 9.26465 5.77777C9.56959 5.12576 10.1693 4.95062 10.6446 4.59652C10.8554 4.43948 11.0476 4.2738 11.1324 4.14036C11.3665 3.77194 11.6006 3.0351 11.4835 2.66669"
                    stroke="#51525C"
                  />
                  <path
                    d="M13.5146 7.33331C13.3683 7.74711 13.2646 8.83331 11.6116 8.85055C11.6116 8.85055 10.1478 8.85055 9.70863 9.67814C9.35731 10.3402 9.56225 11.0575 9.70863 11.3333"
                    stroke="#51525C"
                  />
                  <path
                    d="M8.84766 14.6666V13.3333"
                    stroke="#51525C"
                    strokeLinecap="round"
                  />
                  <path
                    d="M8.84798 14.6667H7.51465"
                    stroke="#51525C"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10.181 14.6667H8.84766"
                    stroke="#51525C"
                    strokeLinecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_420_48527">
                    <rect
                      width={16}
                      height={16}
                      fill="white"
                      transform="translate(0.847656)"
                    />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <img
                src={children[0].props.selectProps.value.flag}
                alt="flag"
                width={24}
                height={24}
              />
            )}
          </span>
          <div className={styles.country}>{children}</div>
        </div>
      </components.Control>
    );
  };

  const colourStylesCountry = {
    control: (styles) => ({
      ...styles,
      textAlign: "start",
      backgroundColor: "#131316",
      width: "100%",
      minHeight: "3.4vh",
      color: "#fff",
      border: `1px solid #26272B`,
      boxShadow: "none",
      borderRadius: "32px",
      fontSize: "1.4vh",
      padding: "0 0.4vh",
      ".custom_select__indicators": {
        ".custom_select__indicator": {
          padding: "0.4vh !important",
        },
      },
    }),
    menu: (styles) => ({
      ...styles,
      background: "transparent",
      boxShadow: "none",
      zIndex: 1000,
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "1.2vh",
    }),
    menuList: (styles) => ({
      ...styles,
      background: "#1D1D20",
      boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.32)",
      color: "#fff",
      borderRadius: "24px",
      padding: "0.4vh 0.4vh",
      zIndex: "1",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "none",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#3F3F46",
        zIndex: "0",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#3F3F46",
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      color: "#fff",
      padding: 0,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#fff",
      padding: 0,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      display: "none",
    }),
    input: (styles) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      textAlign: "start",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "1.2vh",
    }),
    option: (styles, { data, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused || isSelected ? "#161C24" : undefined,
      color: data.color ? data.color : "#FCFCFC",
      fontSize: "1.4vh",
      lineHeight: "1.6vh",
      padding: "1.2vh 0.4vh 1.2vh 1.2vh",
      borderRadius: "32px",
      ":hover": {
        backgroundColor: "#2C2D30",
        color: "#FCFCFC",
      },
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      fontWeight: "500",
      color: "#fff",
      display: "flex",
      alignItems: "center",
      fontSize: "1.2vh",
    }),
    value: (baseStyles) => ({
      ...baseStyles,
      fontSize: "1.2vh",
    }),
  };
  const CustomOptionCountry = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={
          props.isSelected
            ? styles.custom_option_selected
            : styles.custom_option
        }
      >
        <div className={styles.option_country}>
          <img
            src={props.data.flag}
            alt="flag"
            style={{ borderRadius: "50%" }}
          />
          {props.data.label}
        </div>
      </div>
    );
  };
  const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
      <div className={styles.single_value_container}>
        <div className={styles.custom_icon}>
          <svg
            width={"1.7vh"}
            height={"1.6vh"}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5003 6L8.83366 10L4.16699 6"
              stroke="#A0A0AB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {children}
      </div>
    </components.DropdownIndicator>
  );
  return (
    <div className={styles.page}>
      {submit === false ? (
        <>
          <h1>{t("REGISTRATION")}</h1>
          <form>
            {/* <div className={styles.formGroup}>
              <label>{t("Country")}</label>
              <span className={styles.select}>
                <Select
                  classNamePrefix="custom_select"
                  className={`${styles.select_select} custom-select-container`}
                  placeholder={"Country"}
                  menuPlacement="auto"
                  options={Countries.map((el) => ({
                    value: el.name.common,
                    label: el.name.common,
                    flag: el.flags.png,
                  }))}
                  value={country}
                  styles={colourStylesCountry}
                  components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                    Option: CustomOptionCountry,
                    Control,
                  }}
                  onChange={(e) =>
                    setCountry({ value: e.value, label: e.label, flag: e.flag })
                  }
                  //   {errors.country && <p className={styles.error}>{errors.country}</p>}
                />
              </span>
            </div> */}
            <div className={styles.formGroup}>
              <label htmlFor="phone">{t("Phone")}</label>
              <input
                className={styles.input}
                type="tel"
                id="phone"
                placeholder={t("Phone")}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onBlur={() => validateField("phone", phone)}
              />
              {errors.phone && <p className={styles.error}>{errors.phone}</p>}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email">{t("Email")}</label>
              <input
                className={styles.input}
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => validateField("email", email)}
                placeholder={t("Email")}
              />
              {errors.email && <p className={styles.error}>{errors.email}</p>}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="firstName">{t("First Name")}</label>
              <input
                className={styles.input}
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                onBlur={() => validateField("firstName", firstName)}
                placeholder={t("First Name")}
              />
              {errors.firstName && (
                <p className={styles.error}>{errors.firstName}</p>
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="lastName">{t("Last Name")}</label>
              <input
                className={styles.input}
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                onBlur={() => validateField("lastName", lastName)}
                placeholder={t("Last Name")}
              />
              {errors.lastName && (
                <p className={styles.error}>{errors.lastName}</p>
              )}
            </div>

            <button onClick={handleSubmit}>{t("Submit")}</button>
          </form>
        </>
      ) : (
        <div>
          <div className="App">
            <header className="App-header">
              <div className="block">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={46}
                    height={46}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.5"
                      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                      fill="#00B4A0"
                    />
                    <path
                      d="M16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z"
                      fill="#00B4A0"
                    />
                  </svg>
                </span>
                <div>
                  <h2>{t("thank_you_for_registering")}</h2>
                  <p>{t("manager_will_contact_you_soon")}</p>
                </div>
              </div>
            </header>
          </div>
        </div>
      )}
    </div>
  );
}
